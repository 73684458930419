import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Hr, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Адреналин Арена
			</title>
			<meta name={"description"} content={"Отправляйтесь в незабываемое происшествие на Адреналин Арена Paintball, где азарт встречается со стратегией и командной работой."} />
			<meta property={"og:title"} content={"Адреналин Арена"} />
			<meta property={"og:description"} content={"Отправляйтесь в незабываемое происшествие на Адреналин Арена Paintball, где азарт встречается со стратегией и командной работой."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66696d1a3f24780021f7cc67/images/Paintball_Gun_and_Equipment.jpg?v=2024-06-13T09:05:26.723Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66696d1a3f24780021f7cc67/images/Paintball_Gun_and_Equipment.jpg?v=2024-06-13T09:05:26.723Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66696d1a3f24780021f7cc67/images/Paintball_Gun_and_Equipment.jpg?v=2024-06-13T09:05:26.723Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66696d1a3f24780021f7cc67/images/Paintball_Gun_and_Equipment.jpg?v=2024-06-13T09:05:26.723Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66696d1a3f24780021f7cc67/images/Paintball_Gun_and_Equipment.jpg?v=2024-06-13T09:05:26.723Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66696d1a3f24780021f7cc67/images/Paintball_Gun_and_Equipment.jpg?v=2024-06-13T09:05:26.723Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66696d1a3f24780021f7cc67/images/Paintball_Gun_and_Equipment.jpg?v=2024-06-13T09:05:26.723Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link3" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Header>
		<Components.Marque>
			<Override slot="text" />
			<Override slot="text1" />
		</Components.Marque>
		<Section padding="100px 0 100px 0" lg-padding="80px 0 80px 0" quarkly-title="Content-14">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					display="flex"
					flex-direction="row"
					justify-content="center"
					lg-margin="0px 0px 50px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintball-23-scaled.jpg?v=2024-06-13T09:05:26.745Z"
						display="block"
						object-fit="cover"
						height="100%"
						lg-width="100%"
						lg-height="700px"
						sm-height="300px"
						margin="0px 5px 0px 0px"
						md-height="500px"
						srcSet="https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintball-23-scaled.jpg?v=2024-06-13T09%3A05%3A26.745Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintball-23-scaled.jpg?v=2024-06-13T09%3A05%3A26.745Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintball-23-scaled.jpg?v=2024-06-13T09%3A05%3A26.745Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintball-23-scaled.jpg?v=2024-06-13T09%3A05%3A26.745Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintball-23-scaled.jpg?v=2024-06-13T09%3A05%3A26.745Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintball-23-scaled.jpg?v=2024-06-13T09%3A05%3A26.745Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintball-23-scaled.jpg?v=2024-06-13T09%3A05%3A26.745Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/66696d1a3f24780021f7cc67/images/sq-rentals-ag-paintball-opt-895.webp?v=2024-06-13T09:05:26.728Z"
						display="block"
						object-fit="cover"
						height="100%"
						lg-width="100%"
						lg-height="700px"
						sm-height="300px"
						margin="0px 0px 0px 5px"
						md-height="500px"
						srcSet="https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/sq-rentals-ag-paintball-opt-895.webp?v=2024-06-13T09%3A05%3A26.728Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/sq-rentals-ag-paintball-opt-895.webp?v=2024-06-13T09%3A05%3A26.728Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/sq-rentals-ag-paintball-opt-895.webp?v=2024-06-13T09%3A05%3A26.728Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/sq-rentals-ag-paintball-opt-895.webp?v=2024-06-13T09%3A05%3A26.728Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/sq-rentals-ag-paintball-opt-895.webp?v=2024-06-13T09%3A05%3A26.728Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/sq-rentals-ag-paintball-opt-895.webp?v=2024-06-13T09%3A05%3A26.728Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/sq-rentals-ag-paintball-opt-895.webp?v=2024-06-13T09%3A05%3A26.728Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					width="50%"
					padding="0px 0px 0px 80px"
					justify-content="center"
					lg-padding="0px 0px 0px 0"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="--headline2"
						sm-font="normal 700 42px/1.2 --fontFamily-sans"
						color="--darkL1"
						lg-margin="0px 0px 35px 0px"
						text-align="center"
					>
						Почувствуйте невероятный адреналин!
					</Text>
					<Text margin="0px 0px 20px 0px" font="normal 400 20px/1.5 --fontFamily-sans" text-align="center">
						Отправляйтесь в незабываемое происшествие на Адреналин Арена Paintball, где азарт встречается со стратегией и командной работой.
					</Text>
					<Hr
						min-height="10px"
						margin="0px 0px 20px 0px"
						width="50px"
						display="flex"
						justify-content="center"
						border-width="2px 0 0 0"
						border-color="--color-darkL1"
					/>
					<Text margin="0px 0px 25px 0px" font="--base" color="--darkL1" text-align="center">
						Независимо от того, вы опытный игрок или новичок, наше современное оборудование предлагает непревзойденный опыт игры в пейнтбол в безопасной и увлекательной среде. Присоединяйтесь к нам, чтобы провести день веселья и создать воспоминания на всю жизнь!
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="100px 0 120px 0"
			background="#111828"
			color="#000000"
			sm-padding="80px 0 90px 0"
			quarkly-title="Statistics-5"
		>
			<Text margin="0px 0px 10px 0px" color="--secondary">
				Наша миссия
			</Text>
			<Text margin="0px 0px 0px 0px" color="--light" font="--headline2">
				О нашем клубе
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				margin="50px 0px 0px 0px"
				lg-flex-direction="column"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="#c0cad8"
					font="normal 300 18px/1.5 --fontFamily-sans"
					padding="0px 25px 0px 0px"
					width="50%"
					lg-width="100%"
					lg-padding="0px 0 30px 0px"
				>
					Адреналин Арена Paintball посвящен предоставлению исключительного пейнтбольного опыта для игроков всех уровней квалификации. Благодаря разнообразным игровым сценариям и первоклассному оборудованию мы гарантируем, что каждый визит будет наполнен весельем и увлечениями. Наш экспертный персонал увлекается пейнтболом и стремится сделать ваш опыт приятным и безопасным, сосредотачиваясь на стратегии, командной работе и честной игре.
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="#c0cad8"
					font="normal 300 18px/1.5 --fontFamily-sans"
					padding="0px 0px 0px 25px"
					width="50%"
					lg-padding="0px 0px 0px 0"
					lg-width="100%"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Что мы предлагаем?
						<br />
					</Strong>
					<br />
					В Адреналин Арена мы выходим за рамки обыкновенного. В нашем клубе есть несколько тематических полей, каждый из которых создан для создания уникальной атмосферы. От густых лесов до тактических городских условий наша среда тщательно создана, чтобы улучшить вашу стратегическую игру.
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				margin="80px 0px 0px 0px"
				display="grid"
				grid-template-columns="repeat(4, 1fr)"
				md-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-rows="auto"
				md-margin="50px 0px 0px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					border-width="0 0 0 1px"
					border-style="solid"
					border-color="rgba(88, 109, 142, 0.5)"
					padding="0px 0px 0px 25px"
					md-margin="0px 0px 25px 0px"
					sm-padding="0px 0px 0px 18px"
				>
					<Text margin="0px 0px 0px 0px" color="--light" font="normal 700 46px/1.2 --fontFamily-sans" sm-font="normal 700 38px/1.2 --fontFamily-sans">
						2020
					</Text>
					<Text margin="15px 0px 0px 0px" color="#c0cad8" font="--lead" sm-margin="8px 0px 0px 0px">
						наша первая локация
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					border-width="0 0 0 1px"
					border-style="solid"
					border-color="rgba(88, 109, 142, 0.5)"
					padding="0px 0px 0px 25px"
					md-margin="0px 0px 25px 0px"
					sm-padding="0px 0px 0px 18px"
				>
					<Text margin="0px 0px 0px 0px" color="--light" font="normal 700 46px/1.2 --fontFamily-sans" sm-font="normal 700 38px/1.2 --fontFamily-sans">
						400+{" "}
					</Text>
					<Text margin="15px 0px 0px 0px" color="#c0cad8" font="--lead" sm-margin="8px 0px 0px 0px">
						довольных клиентов
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					border-width="0 0 0 1px"
					border-style="solid"
					border-color="rgba(88, 109, 142, 0.5)"
					padding="0px 0px 0px 25px"
					sm-padding="0px 0px 0px 18px"
				>
					<Text margin="0px 0px 0px 0px" color="--light" font="normal 700 46px/1.2 --fontFamily-sans" sm-font="normal 700 38px/1.2 --fontFamily-sans">
						5
					</Text>
					<Text margin="15px 0px 0px 0px" color="#c0cad8" font="--lead" sm-margin="8px 0px 0px 0px">
						5 локаций
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					border-width="0 0 0 1px"
					border-style="solid"
					border-color="rgba(88, 109, 142, 0.5)"
					padding="0px 0px 0px 25px"
					sm-padding="0px 0px 0px 18px"
				>
					<Text margin="0px 0px 0px 0px" color="--light" font="normal 700 46px/1.2 --fontFamily-sans" sm-font="normal 700 38px/1.2 --fontFamily-sans">
						20+
					</Text>
					<Text margin="15px 0px 0px 0px" color="#c0cad8" font="--lead" sm-margin="8px 0px 0px 0px">
						корпоративных мероприятий
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Почему Адреналин Арена?
				</Text>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://uploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintballing-firing.jpg?v=2024-06-13T09:05:26.740Z"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
						srcSet="https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintballing-firing.jpg?v=2024-06-13T09%3A05%3A26.740Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintballing-firing.jpg?v=2024-06-13T09%3A05%3A26.740Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintballing-firing.jpg?v=2024-06-13T09%3A05%3A26.740Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintballing-firing.jpg?v=2024-06-13T09%3A05%3A26.740Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintballing-firing.jpg?v=2024-06-13T09%3A05%3A26.740Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintballing-firing.jpg?v=2024-06-13T09%3A05%3A26.740Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66696d1a3f24780021f7cc67/images/paintballing-firing.jpg?v=2024-06-13T09%3A05%3A26.740Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				align-self="flex-end"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					- Динамические поля: каждая игровая зона создана для уникальной тактической задачи, поощряющей стратегическое мышление и командную работу.{"\n"}
					<br />
					<br />
					{"\n"}- Высококачественное снаряжение: мы оснащаем наших игроков лучшими пейнтбольными маркерами и защитным снаряжением, обеспечивающим лучший опыт.{"\n"}
					<br />
					<br />
					{"\n"}- руководство экспертов: наши сотрудники сами являются опытными игроками и предлагают советы, стратегии и поддержку, чтобы помочь вам улучшить вашу игру.{"\n"}
					<br />
					<br />
					{"\n"}- Сообщество и события: присоединяйтесь к сообществу энтузиастов! Участие в наших регулярных мероприятиях, турнирах и вечеринках только для участников.
				</Text>
			</Box>
		</Section>
		<Components.Cta>
			<Override slot="text">
				ПРИСОЕДИНЯЙТЕСЬ К ДВИЖЕНИЮ Адреналин Арена
			</Override>
			<Override slot="text1">
				Испытайте острые ощущения пейнтбола в Адреналин Арена уже сегодня! Независимо от того, хотите ли вы весело провести день с друзьями, корпоративное командообразование или соревнование, Адреналин Арена станет вашим главным местом. Пусть начинаются сражения!
			</Override>
		</Components.Cta>
		<Components.Footer>
			<Override slot="box1" />
			<Override slot="text3" />
			<Override slot="link3">
				+77766451010
			</Override>
			<Override slot="linkBox" />
			<Override slot="box3" />
			<Override slot="link" />
			<Override slot="box" />
			<Override slot="SectionContent" />
			<Override slot="text" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text1" />
			<Override slot="text2" />
			<Override slot="text4" />
			<Override slot="link4" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b35f058e2e8e002176cca3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});